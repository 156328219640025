import {NgModule} from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import {RouterModule as NgRouterModule} from '@angular/router';
// eslint-disable-next-line no-restricted-imports
import {QuicklinkModule} from 'ngx-quicklink';

import {staticImplements} from '../../decorators/static-implements';

type AngularProperties = `ɵ${string}`;
type RouterModuleStaticProperties = Omit<typeof NgRouterModule, 'prototype' | AngularProperties>;

@NgModule({
  imports: [NgRouterModule, QuicklinkModule],
  exports: [NgRouterModule, QuicklinkModule],
})
@staticImplements<RouterModuleStaticProperties>()
export class RouterModule {
  static forChild = NgRouterModule.forChild;
  static forRoot = NgRouterModule.forRoot;
}
