import {Component, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {A11yModule} from '@angular/cdk/a11y';

import {OverlayDirective} from '@shared/modules/overlay/overlay.directive';
import {SvgIconComponent} from '@shared/components/svg-icon/svg-icon.component';
import {TrapScrollDirective} from '@shared/directives/trap-scroll.directive';
import {AbstractDialogComponent} from '@shared/modules/dialog/abstract-dialog.component';
import {TemplateOrTextComponent} from '@shared/components/template-or-text/template-or-text.component';
import {ButtonComponent} from '@shared/components/button/button.component';
import {IconButtonComponent} from '@shared/components/icon-button/icon-button.component';

import {BubbleComponent} from '../../bubble/bubble.component';
import {BubbleActionsDirective} from '../../bubble/bubble-actions.directive';

@Component({
  selector: 'lcap-dialog',
  templateUrl: './dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrl: './dialog.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    A11yModule,
    OverlayDirective,
    SvgIconComponent,
    TrapScrollDirective,
    BubbleComponent,
    ButtonComponent,
    BubbleActionsDirective,
    IconButtonComponent,
    TemplateOrTextComponent,
  ],
})
export class DialogComponent extends AbstractDialogComponent {}
