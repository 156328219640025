export abstract class ScrollableContainer {
  scrollBy: (x: number, y: number) => void;
  scrollTo: (x: number, y: number) => void;

  scrollViewport: {
    top: number;
    left: number;
    height: number;
    width: number;
  };

  scrollTop: number;
  scrollLeft: number;
  scrollWidth: number;
  scrollHeight: number;
}

export class HtmlScrollableContainer implements ScrollableContainer {
  isBrowserViewport = false;

  constructor(private elem: Element) {
    this.isBrowserViewport = this.elem.tagName === 'HTML' || this.elem.tagName === 'BODY';
  }

  get scrollTop(): number {
    return this.elem.scrollTop;
  }

  get scrollLeft(): number {
    return this.elem.scrollLeft;
  }

  get scrollWidth(): number {
    return this.elem.scrollWidth;
  }

  get scrollHeight(): number {
    return this.elem.scrollHeight;
  }

  get element(): Element {
    return this.elem;
  }

  get scrollViewport(): ScrollableContainer['scrollViewport'] {
    if (this.isBrowserViewport) {
      return {
        top: window.pageYOffset,
        left: window.pageXOffset,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }

    const {top, left} = this.elem.getBoundingClientRect();

    return {
      top,
      left,
      width: this.elem.clientWidth,
      height: this.elem.clientHeight,
    };
  }

  scrollBy(deltaX: number, deltaY: number) {
    this.elem.scrollBy(deltaX, deltaY);
  }

  scrollTo(x: number, y: number) {
    this.elem.scrollTo(x, y);
  }
}
