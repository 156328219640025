/**
 * Converts date format to full-date e.g. `M/d/YY` will be converted to `MM/dd/YYYY`
 */
export function toFullDateFormat(format: string): string {
  return (
    format
      .replace(/dd?/, 'dd')
      // Format month token
      .replace(/MM?/, 'MM')
      // Standalone month token
      // https://moment.github.io/luxon/#/formatting?id=table-of-tokens
      .replace(/LL?/, 'LL')
      .replace(/Y+/, 'YYYY')
  );
}
