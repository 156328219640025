import {ChangeDetectionStrategy, Component, Input, TemplateRef} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'w-template-or-text',
  templateUrl: './template-or-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class TemplateOrTextComponent<TContext extends object | undefined = undefined> {
  @Input({required: true}) value: string | TemplateRef<TContext> | null;
  @Input() context?: TContext;

  isTemplateRef(value: string | TemplateRef<TContext> | null): value is TemplateRef<TContext> {
    return value instanceof TemplateRef;
  }
}
