export function scriptLoader(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (Array.from(document.scripts).find(item => item.src === url)) {
      resolve();

      return;
    }

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = url;

    script.onload = () => {
      resolve();
    };

    script.onerror = () => {
      reject();
    };

    document.querySelector('head')!.appendChild(script);
  });
}
