import {inject} from '@angular/core';
import {CanActivateChildFn, CanDeactivateFn} from '@angular/router';

import {PublicAccessService} from '../services/public-access.service';

export const publicPageEnterGuard: CanActivateChildFn = () => {
  inject(PublicAccessService).enable();

  return true;
};

export const publicPageLeaveGuard: CanDeactivateFn<any> = () => {
  inject(PublicAccessService).disable();

  return true;
};
