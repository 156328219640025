import {Injectable} from '@angular/core';

import {getLocaleDateFormat} from '../utils/dates/get-locale-date-format';
import {getLocaleTimeFormat} from '../utils/dates/get-locale-time-format';
import {toFullDateFormat} from '../utils/dates/to-full-date-format';
import {toFullTimeFormat} from '../utils/dates/to-full-time-format';

@Injectable({providedIn: 'root'})
export class I18nService {
  readonly localeDateFormat: string;
  readonly localeFullDateFormat: string;
  readonly localeTimeFormat: string;
  readonly localeFullTimeFormat: string;

  constructor() {
    this.localeDateFormat = getLocaleDateFormat();
    this.localeFullDateFormat = toFullDateFormat(this.localeDateFormat);

    this.localeTimeFormat = getLocaleTimeFormat();
    this.localeFullTimeFormat = toFullTimeFormat(this.localeTimeFormat);
  }
}
