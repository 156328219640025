import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

import {DialogInstance} from '@shared/modules/dialog/dialog.types';

import {AwsWafApiKey, AwsWafCaptchaConfig} from '../../types/aws-waf-captcha.types';
import {CaptchaComponent} from '../captcha/captcha.component';

@Component({
  selector: 'lcap-captcha-dialog',
  templateUrl: './captcha-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, CaptchaComponent],
})
export class CaptchaDialogComponent implements OnInit {
  @Input({required: true}) dialog: DialogInstance<boolean>;
  @Input({required: true}) captchaApiKey: AwsWafApiKey;

  config: AwsWafCaptchaConfig;

  ngOnInit() {
    this.config = {
      skipTitle: true,
      apiKey: this.captchaApiKey,
      onSuccess: () => this.dialog.close(true),
    };
  }
}
