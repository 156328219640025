import {Routes} from '@angular/router';

export const USER_SETTINGS_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('./user-settings.component').then(m => m.UserSettingsComponent),
    children: [
      {
        path: 'profile',
        loadComponent: () =>
          import('./user-profile-settings/user-profile-settings.component').then(m => m.UserProfileSettingsComponent),
      },
      {
        path: 'password',
        loadComponent: () =>
          import('./user-password-settings/user-password-settings.component').then(
            m => m.UserPasswordSettingsComponent,
          ),
      },
      {
        path: 'notifications',
        loadComponent: () =>
          import('./user-notifications-settings/user-notifications-settings.component').then(
            m => m.UserNotificationsSettingsComponent,
          ),
      },
    ],
  },
];
