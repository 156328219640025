import {Subscription} from 'rxjs';
import {DestroyRef, inject} from '@angular/core';

import {nonNullable} from '../utils/non-nullable';

class Subscriptions {
  private subs: Subscription[] = [];

  add(...subs: Array<Subscription | undefined>) {
    this.subs.push(...subs.filter(nonNullable));
  }

  unsubscribe() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

export function subscriptions(): Subscriptions {
  const subs = new Subscriptions();

  inject(DestroyRef).onDestroy(() => {
    subs.unsubscribe();
  });

  return subs;
}
