import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {computed, makeObservable} from 'mobx';
import {defaultsDeep} from 'lodash';
import {CommonModule} from '@angular/common';

import {ReactiveDirective} from '@shared/directives/reactive.directive';
import {PAGE_ERROR_DATA as SHARED_PAGE_ERROR_DATA} from '@shared/services/service-pages.service';
import {ErrorData, ErrorPageParams, ErrorPageType as SharedErrorPageType} from '@shared/services/service-pages.types';
import {ButtonComponent} from '@shared/components/button/button.component';
import {RouterModule} from '@shared/modules/router/router.module';

import {AuthService} from '../../../services/auth.service';
import {RouterService} from '../../../services/router.service';

type ErrorPageType = SharedErrorPageType | 'GENERAL';

const PAGE_ERROR_DATA: Record<ErrorPageType, Omit<ErrorData, 'type'>> = {
  ...SHARED_PAGE_ERROR_DATA,
  GENERAL: {
    title: 'Error loading the page',
    buttons: {
      home: true,
      back: true,
    },
  },
};

@Component({
  selector: 'lcap-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, ButtonComponent],
  hostDirectives: [ReactiveDirective],
})
export class PageErrorComponent implements OnInit {
  data: Omit<ErrorData, 'type'>;
  canGoBack = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: RouterService<ErrorPageParams, {}, ErrorData>,
  ) {
    makeObservable(this);
  }

  ngOnInit() {
    const {params} = this.router;
    const {data} = this.route.snapshot;
    const errorCode = (data as ErrorPageParams).errorCode || params.errorCode;

    this.data = defaultsDeep({}, this.router.navigationState, PAGE_ERROR_DATA[errorCode || 'GENERAL']);
    this.canGoBack = Boolean(this.data.buttons.back) && window.history.length > 1;
  }

  @computed
  get homeUrl(): string | null {
    return this.authService.authenticated ? '/portal' : null;
  }

  goBack() {
    window.history.back();
  }

  refresh() {
    window.location.reload();
  }
}
