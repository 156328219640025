import {Injectable} from '@angular/core';

import {hexToRgba} from '../utils/colors';

import {LcapBrandingTheme, LcapBrandingThemeCssVars} from './abstract-lcap-branding.service';

@Injectable({
  providedIn: 'root',
})
export class LcapBrandingInjectorService {
  protected styleElement: HTMLStyleElement;

  init() {
    this.styleElement = document.createElement('style');
  }

  injectStyles(theme: LcapBrandingTheme) {
    const {primary_color, secondary_color} = theme;

    const cssVars: LcapBrandingThemeCssVars = {
      'lcap-brand-primary': primary_color,
      'lcap-brand-primary-opacity-5': hexToRgba(primary_color, 0.05),
      'lcap-brand-primary-opacity-10': hexToRgba(primary_color, 0.1),
      'lcap-brand-primary-opacity-20': hexToRgba(primary_color, 0.2),
      'lcap-brand-primary-opacity-50': hexToRgba(primary_color, 0.5),
      'lcap-fg-onBrand-primary': secondary_color,
    };

    document.head.appendChild(this.styleElement);

    const stylesheet = this.styleElement.sheet!;

    stylesheet.insertRule(
      `:root {
        color-scheme: 'normal';
        ${Object.keys(cssVars)
          .map(key => `--${key}: ${cssVars[key]};`)
          .join('\n')}
      }`,
    );
  }

  removeStyles() {
    this.styleElement.remove();
  }
}
