import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';

import {AlertService} from '@shared/services/alert';

import {AuthService} from '../services/auth.service';

export const noAuthGuard: CanActivateFn = () => {
  if (!inject(AuthService).authenticated) {
    return true;
  }

  inject(AlertService).info('You are already signed in!');

  return inject(Router).navigateByUrl('/portal', {replaceUrl: true});
};
