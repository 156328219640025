import {HexColor} from '../../types/lib';

export const LCAP_WIDGET_BORDER_COLOR_PALETTE: HexColor[] = [
  '#ced5db',
  '#8ec1f1',
  '#d3abe7',
  '#9ad86a',
  '#f4a293',
  '#ffbb75',
  '#d6c684',
  '#b7baea',
  '#c4a696',
  '#6dc8ce',
  '#dda4c1',
  '#b8afc0',
];

export const LCAP_WIDGET_BACKGROUND_COLOR_PALETTE: HexColor[] = [
  '#ffffff',
  '#c8e2f8',
  '#eacef7',
  '#e0f5cf',
  '#fcded9',
  '#ffe2c1',
  '#e3d9aa',
  '#dadafb',
  '#e2cfc2',
  '#c7eded',
  '#edd1df',
  '#e5e3eb',
];

export const LCAP_WIDGET_TEXT_COLOR_PALETTE: HexColor[] = [
  '#24434f', // $lcap-text-primary
  '#637a87', // $lcap-text-secondary
  '#766484',
  '#5d69b6',
  '#1f75d7',
  '#108291',
  '#5aa028',
  '#703199',
  '#da3b1c',
  '#e67009',
  '#8e7106',
  '#724e3c',
  '#ffffff',
];

export const LCAP_CHART_COLORS_PALETTE: HexColor[] = [
  '#6DC8CE',
  '#8EC1F1',
  '#D3ABE7',
  '#9AD86A',
  '#F4A293',
  '#FFBB75',
  '#D6C684',
  '#B7BAEA',
  '#C4A696',
  '#DDA4C1',
  '#B8AFC0',
  '#FF92AE',
  '#FEC834',
];
