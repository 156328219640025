import {generateLooseDateFormats} from '../utils/dates/generate-loose-date-formats';
import {generateLooseTimeFormats} from '../utils/dates/generate-loose-time-formats';

export class AbstractDateFormatService {
  readonly monthOfYear = 'MMM yyyy';

  protected _dayOfMonth = 'MMM d';
  protected _time = 'h:mm a';
  protected _timeWithSeconds = 'h:mm:ss a';

  protected _inputDate = 'MM/dd/yyyy';
  protected _shortInputDate = 'MM/dd/yy';
  protected _inputTime = 'h:mm a';

  private _inputDateVariations: string[];
  private _shortInputDateVariations: string[];
  private _inputTimeVariations: string[];

  get inputDateTime(): string {
    return `${this._inputDate} ${this._inputTime}`;
  }

  get inputDate(): string {
    return this._inputDate;
  }

  get inputDatePlaceholder(): string {
    return this._inputDate.toUpperCase();
  }

  get inputDateVariations(): string[] {
    return (this._inputDateVariations ||= generateLooseDateFormats(this._inputDate));
  }

  get inputTime(): string {
    return this._inputTime;
  }

  get inputTimePlaceholder(): string {
    return this._inputTime.replace('a', 'AM/PM').toUpperCase();
  }

  get inputTimeHasMeridian(): boolean {
    return this._inputTime.includes('a');
  }

  get inputTimeVariations(): string[] {
    return (this._inputTimeVariations ||= generateLooseTimeFormats(this._inputTime));
  }

  get shortInputDate(): string {
    return this._shortInputDate;
  }

  get shortInputDatePlaceholder(): string {
    return this._shortInputDate.toUpperCase();
  }

  get shortInputDateVariations(): string[] {
    return (this._shortInputDateVariations ||= generateLooseDateFormats(this._shortInputDate));
  }

  get dateTime(): string {
    return `${this._dayOfMonth}, yyyy ${this._time}`;
  }

  get dateTimeWithZone(): string {
    return `${this._dayOfMonth}, yyyy ${this._timeWithSeconds} (ZZZZ)`;
  }

  get date(): string {
    return `${this._dayOfMonth}, yyyy`;
  }

  get dayOfMonth(): string {
    return this._dayOfMonth;
  }

  get time(): string {
    return this._time;
  }

  get timeWithSeconds(): string {
    return this._timeWithSeconds;
  }
}
