export function toggleEvent<K extends keyof WindowEventMap>(
  elem: Window,
  type: K,
  flag: boolean,
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
): void;
export function toggleEvent<K extends keyof DocumentEventMap>(
  elem: Document,
  type: K,
  flag: boolean,
  listener: (this: Document, ev: DocumentEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
): void;
export function toggleEvent<K extends keyof HTMLElementEventMap>(
  elem: Element,
  type: K,
  flag: boolean,
  listener: (this: Element, ev: HTMLElementEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
): void;

export function toggleEvent(
  elem: any,
  type: string,
  flag: boolean,
  listener: (this: any, ev: Event) => any,
  options?: boolean | AddEventListenerOptions,
): void {
  if (flag) {
    elem.addEventListener(type, listener, options);
  } else {
    elem.removeEventListener(type, listener, options);
  }
}
