import {omitBy} from 'lodash';
import {HttpParameterCodec, HttpParams} from '@angular/common/http';

interface UrlQuery {
  [key: string]: any;
}

export function convertUrlQueryToHttpParams(query: UrlQuery): HttpParams;
export function convertUrlQueryToHttpParams(query: UrlQuery | null | undefined): HttpParams | undefined;

export function convertUrlQueryToHttpParams(query: UrlQuery | null | undefined): HttpParams | undefined {
  if (!query) {
    return undefined;
  }

  query = omitBy(query, val => val === undefined || val === null);

  return new HttpParams({
    fromObject: query,
    // Fixes encoding of `+` in request params: https://github.com/angular/angular/issues/11058
    encoder: new ProperQueryEncoder(),
  });
}

class ProperQueryEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(val: string): string {
    return encodeURIComponent(val);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(val: string): string {
    return decodeURIComponent(val);
  }
}
