import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';

import {TimesPipe} from '../../pipes/times.pipe';
import {WSimpleChanges} from '../../types/angular';

export type SpinnerSize = 'small' | 'default' | 'large';

const SMALL_BARS_COUNT = 5;
const DEFAULT_BARS_COUNT = 8;

@Component({
  selector: 'w-spinner',
  templateUrl: './spinner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TimesPipe],
})
export class SpinnerComponent implements OnChanges {
  @Input() size: SpinnerSize = 'default';
  @Input() centered?: boolean;

  public barsCount = DEFAULT_BARS_COUNT;

  ngOnChanges(changes: WSimpleChanges<SpinnerComponent>) {
    if (changes.size) {
      this.barsCount = this.size === 'small' ? SMALL_BARS_COUNT : DEFAULT_BARS_COUNT;
    }
  }
}
