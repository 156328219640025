import {memoize} from 'lodash';
import {DateTime} from 'luxon';

export const getLocaleTimeFormat = memoize((locale: string = navigator.language) => {
  const sampleDate = DateTime.fromObject({hour: 1, minute: 2}, {locale});
  const timeParts = sampleDate.toLocaleParts(DateTime.TIME_SIMPLE);
  const is24HoursFormat = !timeParts.find(part => part.type === 'dayPeriod');

  return timeParts
    .map(({type, value}) => {
      switch (type) {
        case 'dayPeriod':
          return 'a';
        case 'hour':
          return (is24HoursFormat ? 'H' : 'h').repeat(value.length);
        case 'minute':
          return 'm'.repeat(value.length);
        default:
          // `literal` parts contain some strange whitespaces, so we normalize them here
          return value.replace(/\s/g, ' ');
      }
    })
    .join('');
});
