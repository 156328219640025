import {Injectable, OnDestroy, inject} from '@angular/core';

import {HexColor} from '../types/lib';

import {LcapBrandingInjectorService} from './lcap-branding-injector.service';

export interface LcapBrandingTheme {
  primary_color: HexColor;
  secondary_color: HexColor;
  logo_url?: string;
}

export interface LcapBrandingThemeCssVars {
  'lcap-brand-primary': string;
  'lcap-brand-primary-opacity-5': string;
  'lcap-brand-primary-opacity-10': string;
  'lcap-brand-primary-opacity-20': string;
  'lcap-brand-primary-opacity-50': string;
  'lcap-fg-onBrand-primary': string;
}

export const DEFAULT_THEME: LcapBrandingTheme = {
  primary_color: '#108291',
  secondary_color: '#ffffff',
};

@Injectable()
export abstract class AbstractLcapBrandingService implements OnDestroy {
  protected abstract getTheme(): Promise<LcapBrandingTheme>;

  protected theme: LcapBrandingTheme | null = null;

  private lcapBrandingInjector = inject(LcapBrandingInjectorService);

  constructor() {
    this.lcapBrandingInjector.init();
  }

  ngOnDestroy() {
    this.reset();
  }

  async applyTheme(): Promise<void> {
    this.reset();

    this.theme = await this.getTheme();

    this.lcapBrandingInjector.injectStyles(this.theme);
  }

  reset() {
    this.theme = null;
    this.lcapBrandingInjector.removeStyles();
  }
}
