import {isElementVisible} from '../../utils/dom/is-element-visible';
import {Dimensions, Point} from '../../types';

export interface OverlayOrigin {
  isVisible: boolean;
  element: HTMLElement | null;
  getDimensions(): Dimensions;
}

export class ElementOverlayOrigin implements OverlayOrigin {
  constructor(public readonly element: HTMLElement) {}

  get isVisible(): boolean {
    return isElementVisible(this.element);
  }

  getDimensions(): Dimensions {
    return this.element.getBoundingClientRect();
  }
}

export class PointOverlayOrigin implements OverlayOrigin {
  constructor(private point: Point) {}

  get isVisible(): boolean {
    return true;
  }

  get element(): HTMLElement | null {
    return document.elementFromPoint(this.point.x, this.point.y) as HTMLElement | null;
  }

  getDimensions(): Dimensions {
    return {
      left: this.point.x,
      top: this.point.y,
      right: this.point.x,
      bottom: this.point.y,
      width: 0,
      height: 0,
    };
  }
}

// approximate size of cursor
const POINTER_HEIGHT = 8;
const POINTER_WIDTH = 4;

export class PointerOverlayOrigin implements OverlayOrigin {
  constructor(
    public readonly element: HTMLElement,
    public point: Point,
  ) {}

  get isVisible(): boolean {
    return isElementVisible(this.element);
  }

  getDimensions(): Dimensions {
    return {
      left: this.point.x,
      top: this.point.y,
      right: this.point.x + POINTER_WIDTH,
      bottom: this.point.y + POINTER_HEIGHT,
      width: POINTER_WIDTH,
      height: POINTER_HEIGHT,
    };
  }
}
