import {Injectable} from '@angular/core';
import {action, computed, makeObservable, observable} from 'mobx';
import {isEmpty} from 'lodash';
import URL from 'url-parse';

export interface EmbeddingConfig {
  showHeader?: boolean;
  layoutPadding?: string;
}

// `embedding_params` as stringified json:
// %7B%22showHeader%22%3Afalse%2C%22layoutPadding%22%3A%220%200%200%200%22%7D

@Injectable({providedIn: 'root'})
export class EmbeddingService {
  @observable isEmbedded = false;

  @observable.deep private config: EmbeddingConfig = {};

  constructor() {
    makeObservable(this);
  }

  @computed
  get embeddingQueryParams(): {embedding_params: string} | undefined {
    if (!this.isEmbedded || isEmpty(this.config)) {
      return;
    }

    return {embedding_params: JSON.stringify(this.config)};
  }

  @computed
  get showHeader(): boolean {
    return this.config.showHeader ?? true;
  }

  @computed
  get layoutPadding(): string | undefined {
    return this.config.layoutPadding;
  }

  @action
  init() {
    if (!this.isInIframe) {
      return;
    }

    this.isEmbedded = true;

    const parsedUrl = new URL(location.href, true);
    const embeddingParamsString = parsedUrl.query.embedding_params;

    if (!embeddingParamsString) {
      return;
    }

    this.applyEmbeddingParams(embeddingParamsString);

    const cleanQuery = {...parsedUrl.query};

    delete cleanQuery.embedding_params;

    parsedUrl.set('query', cleanQuery);
    window.history.replaceState(null, '', parsedUrl.toString());
  }

  private get isInIframe(): boolean {
    try {
      return window !== window.top;
    } catch {
      /*
       * Catching possible security errors accessing `window.top`.
       * In this case assuming we're in an iframe.
       */
      return true;
    }
  }

  @action
  private applyEmbeddingParams(params: string) {
    let parsedParams: unknown;

    try {
      parsedParams = JSON.parse(params);
    } catch (err) {
      return;
    }

    if (!parsedParams || typeof parsedParams !== 'object') {
      return;
    }

    if ('showHeader' in parsedParams && typeof parsedParams.showHeader === 'boolean') {
      this.config.showHeader = parsedParams.showHeader;
    }

    if ('layoutPadding' in parsedParams && typeof parsedParams.layoutPadding === 'string') {
      this.config.layoutPadding = parsedParams.layoutPadding;
    }
  }
}
