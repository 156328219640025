import {toString} from 'lodash';

export interface UrlParams {
  [key: string]: any;
}

export class UrlPattern {
  constructor(private pattern: string) {}

  url(params?: UrlParams | null): string {
    const urlParams = params || {};

    return (
      this.pattern
        // Replacing parameters
        .replace(/{{\s*(.+?)\s*}}/g, (match, paramName: string) => encodeURIComponent(toString(urlParams[paramName])))
        // Replacing multiple slashes with the single one
        .replace(/\/+/g, '/')
        // Removing trailing slash for urls like `/post/` or `/post/.json`
        .replace(/\/(\.\w+)?$/, '$1')
    );
  }
}
