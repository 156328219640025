<div class="captcha">
  <w-spinner class="captcha__spinner" size="large" [centered]="true" />
  <div class="captcha__content">
    <div #container class="captcha__container"></div>

    <w-empty
      *ngIf="scriptLoadingFailed"
      class="captcha__error"
      header="Can’t load the CAPTCHA"
      description="Something went wrong and the CAPTCHA wasn’t loaded. Refresh the page to try again."
      image="error"
      [hasBorder]="false"
      [hasBackground]="false"
    >
      <ng-template wEmptyActions>
        <w-button type="outline" theme="neutral" (onClick)="refreshPage()"> Refresh the page </w-button>
      </ng-template>
    </w-empty>
  </div>
</div>
