import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {
  CsrfTokenRefreshInterceptor,
  PING_API_SETTINGS,
  PingApiSettings,
  XSRF_HEADER_NAME,
} from '@shared/interceptors/csrf-token-refresh.interceptor';
import {UnauthorizedInterceptor} from '@shared/interceptors/unauthorized.interceptor';

interface HttpInterceptorsModuleOptions {
  xsrfHeaderName: string;
  pingApiSettings: PingApiSettings;
}

@NgModule()
export class HttpInterceptorsModule {
  static withOptions(options: HttpInterceptorsModuleOptions): ModuleWithProviders<HttpInterceptorsModule> {
    return {
      ngModule: HttpInterceptorsModule,
      providers: [
        {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: CsrfTokenRefreshInterceptor, multi: true},
        {provide: XSRF_HEADER_NAME, useValue: options.xsrfHeaderName},
        {provide: PING_API_SETTINGS, useValue: options.pingApiSettings},
      ],
    };
  }
}
