import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

import {SvgIconComponent} from '../svg-icon/svg-icon.component';

@Component({
  selector: 'button[wIconButton]',
  templateUrl: './icon-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent],
})
export class IconButtonComponent {
  @Input({required: true, alias: 'wIconButtonAriaLabel'})
  @HostBinding('attr.aria-label')
  ariaLabel: string;

  @Input({required: true, alias: 'wIconButton'}) iconId: SvgIconComponent['iconId'];
  @Input({alias: 'wIconButtonIconSize'}) iconSize?: SvgIconComponent['size'];
  @Input({alias: 'wIconButtonIconClass'}) iconClass?: SvgIconComponent['iconClass'];
}
