import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PageSpinnerService {
  isHidden$: Observable<boolean>;
  isVisible$: Observable<boolean>;

  private _isVisible = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isVisible$ = this._isVisible.pipe(distinctUntilChanged(), debounceTime(0));
    this.isHidden$ = this.isVisible$.pipe(map(value => !value));
  }

  get isVisible(): boolean {
    return this._isVisible.value;
  }

  show() {
    this._isVisible.next(true);
  }

  hide() {
    this._isVisible.next(false);
  }

  removeInitialSpinner() {
    document.querySelector('.page-spinner_initial')?.remove();
  }
}
