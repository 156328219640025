import {Component, OnInit} from '@angular/core';
import {Settings} from 'luxon';

import {ServicePagesService} from '@shared/services/service-pages.service';

import {BrandingService} from '../../services/branding.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'lcap-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private brandingService: BrandingService,
    private servicePages: ServicePagesService,
  ) {}

  async ngOnInit() {
    try {
      await this.authService.init();
    } catch (err) {
      this.servicePages.showPageLoadError({err});
    }

    this.initDateTimeSettings();
    this.brandingService.applyTheme();
  }

  private initDateTimeSettings() {
    Settings.defaultLocale = 'en-US';
  }
}
