export function generateLooseDateFormats(originalFormat: string): string[] {
  const formats: string[] = [originalFormat];

  formats.push(originalFormat.replace(/M{1,2}/, match => (match.length === 1 ? 'MM' : 'M')));

  for (const format of [...formats]) {
    formats.push(format.replace(/d{1,2}/, match => (match.length === 1 ? 'dd' : 'd')));
  }

  return formats;
}
