<div class="page-error">
  <div class="page-error__content">
    <h1 class="page-error__title" [innerHTML]="data.title"></h1>
    <div *ngIf="data.description" class="page-error__description" [innerHTML]="data.description"></div>
    <div class="page-error__cta">
      <w-button *ngIf="canGoBack" (onClick)="goBack()">Go back</w-button>
      <w-button *ngIf="data.buttons.refresh" (onClick)="refresh()">Refresh</w-button>
      <w-button *ngIf="homeUrl" [routerLink]="homeUrl">Go to home page</w-button>
    </div>
  </div>
</div>
