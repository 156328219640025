import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SpinnerComponent} from '../spinner/spinner.component';
import {TemplateOrTextComponent} from '../template-or-text/template-or-text.component';

import {EmptyActionsDirective} from './empty-actions.directive';
import {EmptyDescriptionDirective} from './empty-description.directive';
import {EmptyHeaderDirective} from './empty-header.directive';
import {EmptyComponent} from './empty.component';

const directives = [EmptyHeaderDirective, EmptyDescriptionDirective, EmptyActionsDirective];

@NgModule({
  imports: [CommonModule, TemplateOrTextComponent, SpinnerComponent],
  declarations: [EmptyComponent, ...directives],
  exports: [EmptyComponent, ...directives],
})
export class EmptyModule {}
