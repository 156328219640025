import {escape} from 'lodash';
import {MarkedOptions, Renderer, marked} from 'marked';
import {Inject, Injectable, InjectionToken} from '@angular/core';

import {HtmlSanitizerConfig, HtmlSanitizerService} from './html-sanitizer.service';

export type MarkdownOptions = Omit<MarkedOptions, 'async'> &
  HtmlSanitizerConfig & {
    linksTarget?: '_self' | '_blank';
  };

export const DEFAULT_MARKDOWN_OPTIONS: MarkdownOptions = {
  allowedTags: 'h1 h2 h3 h4 h5 h6 p a img pre code ul ol li div span b strong em br',
  allowedAttrs: 'id href src alt width height',
  allowDataAttributes: false,
  gfm: true,
  breaks: true,
  linksTarget: '_self',
};

export const DEFAULT_MARKDOWN_OPTIONS_TOKEN = new InjectionToken<MarkdownOptions>('DEFAULT_MARKDOWN_OPTIONS', {
  factory: () => DEFAULT_MARKDOWN_OPTIONS,
});

@Injectable({
  providedIn: 'root',
})
export class MarkdownService {
  constructor(
    private htmlSanitizer: HtmlSanitizerService,
    @Inject(DEFAULT_MARKDOWN_OPTIONS_TOKEN) private defaultMarkdownOptions: MarkdownOptions,
  ) {}

  render(markdown: string, options?: MarkdownOptions): string {
    options = {
      ...this.defaultMarkdownOptions,
      ...options,
    };

    if (options.linksTarget) {
      options.renderer = this.createRenderer(options);
    }

    try {
      return this.htmlSanitizer.sanitize(marked(markdown, options) as string, options);
    } catch {
      return '';
    }
  }

  private createRenderer(options: MarkdownOptions): Renderer {
    const renderer = new Renderer();

    renderer.link = this.linkRenderer.bind(this, options);

    return renderer;
  }

  private linkRenderer(options: MarkdownOptions, href: string, title: string, text: string): string {
    let out = `<a href="${escape(href)}" target="${escape(options.linksTarget)}"`;

    if (title) {
      out += ` title="${escape(title)}"`;
    }

    out += `>${text}</a>`;

    return out;
  }
}
