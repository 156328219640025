import DomPurify from 'dompurify';
import {Injectable} from '@angular/core';

export interface HtmlSanitizerConfig {
  // Default: all safe tags according to `dompurify`
  allowedTags?: string;
  // Default: all safe attributes according to `dompurify`
  allowedAttrs?: string;
  // Default: `true`
  allowDataAttributes?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HtmlSanitizerService {
  sanitize(html: string, config?: HtmlSanitizerConfig): string {
    const options = this.convertConfig(config);

    return options ? (DomPurify.sanitize(html, options) as string) : DomPurify.sanitize(html);
  }

  private convertConfig(config: HtmlSanitizerConfig | undefined): DomPurify.Config | undefined {
    if (!config) {
      return undefined;
    }

    const domPurifyConfig: DomPurify.Config = {
      ALLOW_DATA_ATTR: config.allowDataAttributes !== false,
    };

    if (config.allowedTags) {
      domPurifyConfig.ALLOWED_TAGS = config.allowedTags.split(' ');
    }

    if (config.allowedAttrs) {
      domPurifyConfig.ALLOWED_ATTR = config.allowedAttrs.split(' ');
    }

    return domPurifyConfig;
  }
}
