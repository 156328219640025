import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {flow, makeObservable, observable} from 'mobx';

import {scriptLoader} from '@shared/utils/script-loader';
import {ReactiveDirective} from '@shared/directives/reactive.directive';
import {SpinnerComponent} from '@shared/components/spinner/spinner.component';
import {EmptyModule} from '@shared/components/empty/empty.module';
import {ButtonComponent} from '@shared/components/button/button.component';

import {AwsWafCaptchaConfig} from '../../types/aws-waf-captcha.types';

import {TestCaptchaComponent} from './test-captcha.component';

const CAPTCHA_SCRIPT_URL = 'https://a7c92b91aa06.us-east-1.captcha-sdk.awswaf.com/a7c92b91aa06/jsapi.js';
const TEST_CAPTCHA_COMPONENT = TEST ? TestCaptchaComponent : null;

@Component({
  selector: 'lcap-captcha',
  templateUrl: './captcha.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './captcha.component.scss',
  standalone: true,
  imports: [CommonModule, SpinnerComponent, EmptyModule, ButtonComponent],
  hostDirectives: [ReactiveDirective],
})
export class CaptchaComponent implements OnInit {
  @Input({required: true}) @observable.ref config: AwsWafCaptchaConfig;

  @observable scriptLoadingFailed = false;

  @ViewChild('container', {static: true}) container: ElementRef<HTMLElement>;
  @ViewChild('container', {static: true, read: ViewContainerRef}) containerRef: ViewContainerRef;

  constructor() {
    makeObservable(this);
  }

  @flow
  *ngOnInit(): Generator<any, void, any> {
    if (TEST) {
      this.createCaptchaStub();

      return;
    }

    try {
      yield scriptLoader(CAPTCHA_SCRIPT_URL);
    } catch {
      this.scriptLoadingFailed = true;

      return;
    }

    window.AwsWafCaptcha.renderCaptcha(this.container.nativeElement, this.config);
  }

  refreshPage() {
    location.reload();
  }

  private createCaptchaStub() {
    this.containerRef.createComponent(TEST_CAPTCHA_COMPONENT!).setInput('config', this.config);
  }
}
