import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from '../services/auth.service';

export const authGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);

  if (authService.authenticated || route.data.skipAuthGuard) {
    return true;
  }

  authService.returnUrl = state.url;

  return inject(Router).navigateByUrl(authService.signInPath, {replaceUrl: true});
};
