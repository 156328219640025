import {ApplicationRef, DoBootstrap, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {APP_BASE_HREF} from '@angular/common';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration} from '@angular/common/http';
import {HotkeyModule} from 'angular2-hotkeys';

import {AbstractScreenSizeService} from '@shared/services/abstract-screen-size.service';
import {AbstractAuthService} from '@shared/services/abstract-auth.service';
import {AbstractDateFormatService} from '@shared/services/abstract-date-format.service';
import {GLOBAL_CONFIG_TOKEN} from '@shared/tokens/global-config.token';
import {PageSpinnerService} from '@shared/services/page-spinner.service';
import {OverlayModule} from '@shared/modules/overlay/overlay.module';
import {DEFAULT_MARKDOWN_OPTIONS_TOKEN} from '@shared/services/markdown.service';
import {configureMobx} from '@shared/lib/configure-mobx';
import {RouterModule} from '@shared/modules/router/router.module';
import {AbstractLcapPageConfigService} from '@shared/modules/lcap/abstract-lcap-page-config.service';
import {LcapPageConfigService} from '@shared/modules/lcap/lcap-page-config.service';

import {HttpInterceptorsModule} from './interceptors/http-interceptors.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './components/app/app.component';
import {ScreenSizeService} from './services/screen-size.service';
import {AuthService} from './services/auth.service';
import {DateFormatService} from './services/date-format.service';
import {DevelopmentErrorHandler, ProductionErrorHandler} from './error-handlers';
import {DEFAULT_MARKDOWN_OPTIONS} from './constants/markdown.constants';
import {EmbeddingService} from './services/embedding.service';
import {Environment} from './services/environment.service';

const XSRF_HEADER_NAME = 'X-CSRF-TOKEN';
const XSRF_COOKIE_NAME = 'XSRF-TOKEN';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    OverlayModule,
    AppRoutingModule,
    TEST ? NoopAnimationsModule : BrowserAnimationsModule,
    HttpInterceptorsModule.withOptions({
      xsrfHeaderName: XSRF_HEADER_NAME,
      pingApiSettings: {url: '/portal/api/ping', responseType: 'json'},
    }),
    HotkeyModule.forRoot(),
  ],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
      withXsrfConfiguration({
        cookieName: XSRF_COOKIE_NAME,
        headerName: XSRF_HEADER_NAME,
      }),
    ),
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: ErrorHandler,
      useClass: PRODUCTION ? ProductionErrorHandler : DevelopmentErrorHandler,
    },
    {
      provide: AbstractScreenSizeService,
      useClass: ScreenSizeService,
    },
    {
      provide: AbstractAuthService,
      useExisting: AuthService,
    },
    {
      provide: AbstractDateFormatService,
      useExisting: DateFormatService,
    },
    {
      provide: GLOBAL_CONFIG_TOKEN,
      useValue: window.Lcap,
    },
    {
      provide: DEFAULT_MARKDOWN_OPTIONS_TOKEN,
      useValue: DEFAULT_MARKDOWN_OPTIONS,
    },
    {provide: AbstractLcapPageConfigService, useClass: LcapPageConfigService},
  ],
})
export class AppModule implements DoBootstrap {
  constructor(
    private errorHandler: ErrorHandler,
    private pageSpinner: PageSpinnerService,
    private embeddingService: EmbeddingService,
    private env: Environment,
  ) {}

  ngDoBootstrap(appRef: ApplicationRef) {
    configureMobx(this.errorHandler);

    this.env.initialize();
    this.pageSpinner.removeInitialSpinner();
    this.embeddingService.init();

    appRef.bootstrap(AppComponent);
  }
}
