import {Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit, inject} from '@angular/core';

export type ThemingContext = 'app' | 'lcap';

export function getThemingContextClasses(context: ThemingContext): string[] {
  return ['theming-context', `theming-context_${context}`];
}

@Directive({
  selector: '[wThemingContext]',
  standalone: true,
})
export class ThemingContextDirective implements OnInit, OnDestroy {
  @Input({alias: 'wThemingContext'}) contextInput: ThemingContext | 'inherit' = 'app';

  @HostBinding('class')
  get class(): string {
    return getThemingContextClasses(this.currentContext).join(' ');
  }

  protected childContexts = new Map<ThemingContextDirective, HTMLElement>();

  private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private parentDirective = inject(ThemingContextDirective, {optional: true, skipSelf: true});

  ngOnInit() {
    if (this.contextInput === 'inherit') {
      this.parentDirective?.addChild(this);
    }
  }

  ngOnDestroy() {
    this.parentDirective?.removeChild(this);
  }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  protected addChild(childContext: ThemingContextDirective) {
    this.childContexts.set(childContext, childContext.element);

    if (this.contextInput === 'inherit') {
      this.parentDirective?.addChild(childContext);
    }
  }

  protected removeChild(childContext: ThemingContextDirective) {
    this.childContexts.delete(childContext);
    this.parentDirective?.removeChild(childContext);
  }

  private get currentContext(): ThemingContext {
    return this.contextInput === 'inherit' ? this.parentDirective?.currentContext ?? 'app' : this.contextInput;
  }
}
