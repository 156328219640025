export type ActionsScheduler = (action: Action) => void;

type Action = () => void;

export function createActionsScheduler(delay?: number): ActionsScheduler {
  let timerId: number | null = null;
  let actions: Action[] = [];

  return function scheduleAction(action: Action) {
    if (!timerId) {
      timerId = setTimeout(() => {
        actions.forEach(scheduledAction => scheduledAction());
        actions = [];
        timerId = null;
      }, delay);
    }

    actions.push(action);
  };
}
