import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';

import {AuthService} from '../services/auth.service';

export const authInfoInitializedGuard: CanActivateFn = async () => {
  try {
    await inject(AuthService).init();
  } catch {
    // handled by `AppComponent`
  }

  return true;
};
