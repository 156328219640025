import {ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, ViewEncapsulation} from '@angular/core';

import {EmptyActionsDirective} from './empty-actions.directive';
import {EmptyDescriptionDirective} from './empty-description.directive';
import {EmptyHeaderDirective} from './empty-header.directive';

export type EmptySize = 'large' | 'medium' | 'small';
export type EmptyImage =
  | 'asset'
  | 'empty'
  | 'recipe'
  | 'trash'
  | 'dependency-graph'
  | 'dashboard'
  | 'data'
  | 'users'
  | 'search'
  | 'redirect'
  | 'custom-web'
  | 'email-sent'
  | 'on-prem'
  | 'workbot'
  | 'custom-bot'
  | 'security'
  | 'stopped'
  | 'error'
  | 'coding'
  | 'disconnected'
  | 'connected'
  | 'community'
  | 'preparing'
  | 'test'
  | 'postman'
  | 'v1-missing'
  | 'v2-missing'
  | 'both-missing'
  | 'erased'
  | 'scim'
  | 'lcap-app'
  | 'lcap-layout'
  | 'ai-chat'
  | 'recipe-copilot'
  | 'placeholder';

@Component({
  selector: 'w-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyComponent {
  @Input('header') headerInput: string | null = null;
  @Input('description') descriptionInput: string | null = null;
  @Input() image: EmptyImage | 'spinner' = 'empty';
  @Input() size: EmptySize = 'large';
  @Input() hasBorder = true;
  @Input() hasBackground = true;
  @Input() hasImage = true;
  @Input() hasPadding = true;
  @Input() placeholder = false;

  @ContentChild(EmptyHeaderDirective, {read: TemplateRef}) headerTemplate: TemplateRef<void>;
  @ContentChild(EmptyDescriptionDirective, {read: TemplateRef}) descriptionTemplate: TemplateRef<void>;
  @ContentChild(EmptyActionsDirective, {read: TemplateRef}) actionsTemplate: TemplateRef<void>;

  get header(): string | TemplateRef<void> | undefined | null {
    return this.headerTemplate ? this.headerTemplate : this.headerInput;
  }

  get description(): string | TemplateRef<void> | undefined | null {
    return this.descriptionTemplate ? this.descriptionTemplate : this.descriptionInput;
  }
}
