import {Injectable} from '@angular/core';

@Injectable()
export abstract class AbstractEnvironmentService {
  isSafari: boolean;

  initialize() {
    this.checkIsSafari();
  }

  private checkIsSafari() {
    /**
     * Detects Safari browser
     * Copied from https://stackoverflow.com/a/31732310
     */
    this.isSafari = Boolean(
      navigator.vendor?.includes('Apple') &&
        !navigator.userAgent?.includes('CriOS') &&
        !navigator.userAgent?.includes('FxiOS'),
    );
  }
}
