import {MarkdownOptions} from '@shared/services/markdown.service';

export const DEFAULT_MARKDOWN_OPTIONS: MarkdownOptions = {
  allowedTags: 'a ul ol li span b strong em br',
  allowedAttrs: 'href target',
  allowDataAttributes: false,
  gfm: true,
  breaks: true,
  linksTarget: '_blank',
};
