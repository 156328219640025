import {Observable} from 'rxjs';

import {ScreenSizeName} from '../constants/screen-sizes';

export interface ScreenSize {
  name: ScreenSizeName;
  maxWidth: number;
}

export abstract class AbstractScreenSizeService implements Record<ScreenSizeName, ScreenSize> {
  abstract current: ScreenSize;
  abstract phone: ScreenSize;
  abstract phablet: ScreenSize;
  abstract tablet: ScreenSize;
  abstract compact: ScreenSize;
  abstract desktopSmall: ScreenSize;
  abstract desktopHD: ScreenSize;
  abstract desktopFullHD: ScreenSize;
  abstract desktopMax: ScreenSize;
  abstract change: Observable<ScreenSize>;
}
