import {Injectable} from '@angular/core';
import {makeObservable, observable} from 'mobx';
import {Title} from '@angular/platform-browser';

import {AbstractLcapBrandingService, LcapBrandingTheme} from '@shared/services/abstract-lcap-branding.service';

import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class BrandingService extends AbstractLcapBrandingService {
  @observable logoUrl?: string;

  constructor(
    private authService: AuthService,
    private ngTitle: Title,
  ) {
    super();
    makeObservable(this);
  }

  override async applyTheme(): Promise<void> {
    this.ngTitle.setTitle(this.authService.settings.name);
    await super.applyTheme();
    this.logoUrl = this.theme!.logo_url;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  protected async getTheme(): Promise<LcapBrandingTheme> {
    return this.authService.settings.branding;
  }
}
