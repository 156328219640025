import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ElementResizeDirective} from '../../directives/element-resize.directive';
import {ThemingContextDirective} from '../../directives/theming-context.directive';

import {OverlayDirective} from './overlay.directive';
import {OverlayContentDirective} from './overlay-content.directive';
import {OverlayComponent} from './overlay/overlay.component';

@NgModule({
  imports: [CommonModule, ElementResizeDirective, OverlayDirective, OverlayContentDirective, ThemingContextDirective],
  declarations: [OverlayComponent],
  exports: [OverlayComponent, OverlayDirective, OverlayContentDirective],
})
export class OverlayModule {}
