import {PaletteColor} from '../../types/lib';
import {ThemingContext} from '../../directives/theming-context.directive';
import {EmptyImage} from '../../components/empty/empty.component';

export abstract class AbstractLcapPageConfigService {
  abstract emptyImage: EmptyImage;
  abstract emptyWidgetEditorHeader: string;
  abstract entityName: string;
  abstract themingContext: ThemingContext;
  abstract usesDataTables: boolean;
  abstract usesAppFunctions: boolean;
  abstract usesBranding: boolean;
  abstract supportsConditionalVisibility: boolean;
  abstract supportsImageWidget: boolean;
  abstract supportsCustomPageBackgroundColors: boolean;
  abstract supportsDataTree: boolean;
  abstract supportsDarkMode: boolean;
  abstract chartColorsPalette: PaletteColor[];
  abstract allowCustomColor: boolean;
  abstract widgetBorderColorPalette: PaletteColor[];
  abstract widgetBackgroundColorPalette: PaletteColor[];
  abstract widgetTextColorPalette: PaletteColor[];
}
