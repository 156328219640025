const PREVIOUS_DISPLAY_KEY = Symbol('previousDisplay');

export function toggleElement(elem: HTMLElement | SVGElement, show: boolean) {
  if (show) {
    elem.style.display = elem[PREVIOUS_DISPLAY_KEY] || '';

    if (getCurrentStyle(elem, 'display') === 'none') {
      elem.style.display = getDefaultDisplay(elem);
    }
  } else {
    elem[PREVIOUS_DISPLAY_KEY] = getCurrentStyle(elem, 'display');
    elem.style.display = 'none';
  }
}

export function showElement(elem: HTMLElement | SVGElement) {
  toggleElement(elem, true);
}

export function hideElement(elem: HTMLElement | SVGElement) {
  toggleElement(elem, false);
}

function getCurrentStyle<TProp extends keyof CSSStyleDeclaration>(
  elem: HTMLElement | SVGElement,
  prop: TProp,
): CSSStyleDeclaration[TProp] {
  const style = window.getComputedStyle(elem, null);

  return style[prop] || elem.style[prop];
}

const DEFAULT_TAG_DISPLAY: Record<string, string> = Object.create(null);

function getDefaultDisplay(elem: HTMLElement | SVGElement): CSSStyleDeclaration['display'] {
  const {tagName} = elem;

  if (DEFAULT_TAG_DISPLAY[tagName]) {
    return DEFAULT_TAG_DISPLAY[tagName];
  }

  const tmpElem = document.createElement(tagName);

  document.body.appendChild(tmpElem);

  const display = getCurrentStyle(tmpElem, 'display');

  document.body.removeChild(tmpElem);

  DEFAULT_TAG_DISPLAY[tagName] = display === 'none' ? 'block' : display;

  return DEFAULT_TAG_DISPLAY[tagName];
}
