import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Log {
  disabled = false;

  constructor() {
    this.disabled = PRODUCTION;
  }

  log(...args: any[]) {
    this._log('log', ...args);
  }

  info(...args: any[]) {
    this._log('info', ...args);
  }

  warn(...args: any[]) {
    this._log('warn', ...args);
  }

  error(...args: any[]) {
    this._log('error', ...args);
  }

  private _log(type: string, ...args: any[]) {
    if (this.disabled) {
      return;
    }

    if (type === 'error') {
      // Mapping errors in arguments to their stack trace for more readable output
      args = args.map(arg => (arg instanceof Error ? `\n${arg.stack}` : arg));
    }

    // eslint-disable-next-line no-console
    console[type](...args);
  }
}
