import {Injectable} from '@angular/core';

import {AbstractDialogService} from '@shared/modules/dialog/abstract-dialog.service';

import {DialogComponent} from '../components/dialog/dialog/dialog.component';
import {FullscreenDialogComponent} from '../components/dialog/fullscreen-dialog/fullscreen-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class RootDialogService extends AbstractDialogService {
  protected dialogComponent = DialogComponent;
  protected fullscreenDialogComponent = FullscreenDialogComponent;

  // eslint-disable-next-line workato/explicit-function-return-type
  protected confirmationDialogComponentLoader = () =>
    import('../components/dialog/confirmation-dialog/confirmation-dialog.component').then(
      m => m.ConfirmationDialogComponent,
    );
}
