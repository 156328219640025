import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';

import {ComponentClass} from '../../types/angular';
import {OverlayDirective} from '../overlay/overlay.directive';

import {DialogContentComponent, DialogInstance, DialogOptions} from './dialog.types';
import {DIALOG_ARIA_LABEL} from './dialog.tokens';

@Directive()
export abstract class AbstractDialogComponent implements OnInit {
  @Input({required: true}) content: TemplateRef<any> | ComponentClass<DialogContentComponent>;
  @Input({required: true}) overlay: DialogInstance;
  @Input() class?: string;
  @Input() style: object | null = null;
  @Input() contentInputs: object | null = null;
  @Input() cancellable = true;
  @Input() closeButtonVisible = true;
  @Input() closeOnBackdropClick = true;
  @Input() width?: number;
  @Input() closeConfirmation?: DialogOptions['closeConfirmation'];
  @Input() closeConfirmationButtons?: DialogOptions['closeConfirmationButtons'];
  @Input() closeConfirmationCallback?: DialogOptions['closeConfirmationCallback'];
  @Input() trapFocusAutoCapture: DialogOptions['trapFocusAutoCapture'] = true;

  @ViewChild('contentContainer', {static: true}) contentContainer: ElementRef<HTMLElement>;
  @ViewChild('componentContainer', {read: ViewContainerRef, static: true}) componentContainer: ViewContainerRef;
  @ViewChild('confirmationPopover') confirmationPopover?: OverlayDirective;

  @HostBinding('attr.aria-labelledby') ariaLabelledBy: string | null = null;
  @HostBinding('attr.aria-modal') ariaModal = true;
  @HostBinding('attr.aria-role') ariaRole = 'dialog';

  selecting = false;

  protected changeDetector = inject(ChangeDetectorRef);
  protected ariaLabel = inject(DIALOG_ARIA_LABEL, {optional: true});

  constructor() {
    this.ariaLabelledBy = this.ariaLabel;
  }

  ngOnInit() {
    if (!this.isTemplateContent(this.content)) {
      this.showComponent(this.content);
    }
  }

  get closeButtonTitle(): string {
    return this.closeConfirmationButtons?.close || 'Close';
  }

  get cancelButtonTitle(): string {
    return this.closeConfirmationButtons?.cancel || 'Cancel';
  }

  get isCloseButtonShown(): boolean {
    return this.cancellable && this.closeButtonVisible;
  }

  isTemplateContent(content: AbstractDialogComponent['content']): content is TemplateRef<any> {
    return content instanceof TemplateRef;
  }

  close() {
    this.overlay.close();
  }

  handleDialogClick(event: MouseEvent) {
    if (
      this.cancellable &&
      !this.selecting &&
      !this.closeConfirmation &&
      // Click was made exactly on `w-dialog` which is, basically, `w-dialog__overlay` as it has `pointer-events: none`
      event.currentTarget === event.target &&
      this.closeOnBackdropClick
    ) {
      this.close();
    }

    this.selecting = false;
  }

  handleContentMousedown() {
    // Prevents closing dialog on content selection https://github.com/workato/issues/issues/9481
    this.selecting = true;
  }

  handleContentClick() {
    this.selecting = false;
  }

  handleClose() {
    const shouldShowConfirmation =
      Boolean(this.closeConfirmation) && (!this.closeConfirmationCallback || this.closeConfirmationCallback());

    if (shouldShowConfirmation) {
      this.confirmationPopover!.show();
    } else {
      this.close();
    }
  }

  private showComponent(content: ComponentClass<DialogContentComponent>) {
    const componentRef = this.componentContainer.createComponent(content);

    Object.assign(componentRef.instance, this.contentInputs, {
      dialog: this.overlay,
    });
    /*
     * Official solution for "Expression has changed after it was checked" error
     * See https://github.com/angular/angular/issues/17572#issuecomment-364175055
     */
    this.changeDetector.detectChanges();
  }
}
