import {HttpClient} from '@angular/common/http';
import {inject} from '@angular/core';

import {ResourceConfig, HttpResource as SharedHttpResource} from '@shared/services/http-resource';

export {Request} from '@shared/services/http-resource';

export class HttpResource extends SharedHttpResource {
  constructor(config: ResourceConfig) {
    super(inject(HttpClient), config);
  }
}
