import {Injectable, OnDestroy} from '@angular/core';
import {IReactionDisposer, action, autorun, computed, makeObservable, observable} from 'mobx';
import {ReplaySubject} from 'rxjs';

import {
  AbstractScreenSizeService,
  ScreenSize as ScreenSizeInterface,
} from '@shared/services/abstract-screen-size.service';
import {SCREEN_SIZES, SCREEN_SIZE_NAMES, ScreenSizeName} from '@shared/constants/screen-sizes';

import {createActionsScheduler} from '../utils/actions-scheduler';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService extends AbstractScreenSizeService implements OnDestroy {
  phone = new ScreenSize('phone');
  phablet = new ScreenSize('phablet');
  tablet = new ScreenSize('tablet');
  compact = new ScreenSize('compact');
  desktopSmall = new ScreenSize('desktopSmall');
  desktopHD = new ScreenSize('desktopHD');
  desktopFullHD = new ScreenSize('desktopFullHD');
  desktopMax = new ScreenSize('desktopMax');
  change = new ReplaySubject<ScreenSize>(1);

  protected disposeScreenSizeChangeReaction: IReactionDisposer;

  constructor() {
    super();
    makeObservable(this);

    this.disposeScreenSizeChangeReaction = autorun(() => {
      this.change.next(this.current);
    });
  }

  ngOnDestroy() {
    this.disposeScreenSizeChangeReaction();

    for (const screenSizeName of SCREEN_SIZE_NAMES) {
      this[screenSizeName].dispose();
    }
  }

  @computed
  get current(): ScreenSize {
    for (const screenSizeName of SCREEN_SIZE_NAMES) {
      if (this[screenSizeName].active) {
        return this[screenSizeName];
      }
    }

    return this.desktopMax;
  }

  /**
   * Current page width less than `phone` screen size
   */
  @computed
  get isPhoneView(): boolean {
    return this.current.maxWidth <= this.phone.maxWidth;
  }

  /**
   * Current page width less than `compact` screen size
   */
  @computed
  get isCompactView(): boolean {
    return this.current.maxWidth <= this.compact.maxWidth;
  }

  /**
   * Current page width is larger than `compact` screen size
   */
  @computed
  get isDesktopView(): boolean {
    return !this.isCompactView;
  }
}

class ScreenSize implements ScreenSizeInterface {
  protected static scheduleAction = createActionsScheduler();

  @observable active: boolean;
  minWidth: number;
  maxWidth: number;

  protected mediaQueryList: MediaQueryList;

  constructor(public name: ScreenSizeName) {
    makeObservable(this);

    const mediaQueries: string[] = [];
    const prevScreenSizeName: string | undefined = SCREEN_SIZE_NAMES[SCREEN_SIZE_NAMES.indexOf(name) - 1];

    this.maxWidth = SCREEN_SIZES[name];
    this.minWidth = prevScreenSizeName ? SCREEN_SIZES[prevScreenSizeName] + 1 : 0;

    if (isFinite(this.maxWidth)) {
      mediaQueries.push(`(max-width: ${this.maxWidth}px)`);
    }

    if (this.minWidth) {
      mediaQueries.push(`(min-width: ${this.minWidth}px)`);
    }

    this.mediaQueryList = matchMedia(mediaQueries.join(' and '));
    this.updateActive();
    /*
     * Need to schedule update of the `active` observable because callback for the previous screen size may be called
     * first, setting `active` prop for it to `false` and in this case there won't be active screen sizes. So we
     * need to wait for the new screen size's callback to be called and only then update all `active` props.
     */
    this.mediaQueryList.addEventListener('change', this.scheduleUpdateActive);
  }

  dispose() {
    this.mediaQueryList?.removeEventListener('change', this.scheduleUpdateActive);
  }

  @action
  protected updateActive() {
    this.active = this.mediaQueryList.matches;
  }

  @action.bound
  protected scheduleUpdateActive() {
    ScreenSize.scheduleAction(() => this.updateActive());
  }
}
