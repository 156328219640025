import {
  HorizontalPlacement,
  OverlayContent,
  OverlayContentComponent,
  OverlayContentTemplate,
  OverlayPlacement,
  VerticalPlacement,
} from './overlay.types';

interface ConvertOptions {
  disableOpposite?: boolean;
}

const OPPOSITE_PLACEMENT: Record<HorizontalPlacement, HorizontalPlacement> &
  Record<VerticalPlacement, VerticalPlacement> = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
  center: 'center',
};

export function convertPlacement(placement: string, options: ConvertOptions = {}): OverlayPlacement | null {
  const [side, align = 'center'] = placement.split(' ');
  const placements = Object.keys(OPPOSITE_PLACEMENT);

  if (!side || !placements.includes(side) || !placements.includes(align)) {
    return null;
  }

  let originX: HorizontalPlacement;
  let originY: VerticalPlacement;
  let overlayX: HorizontalPlacement;
  let overlayY: VerticalPlacement;

  if (side === 'top' || side === 'bottom' || side === 'center') {
    originX = overlayX = align as HorizontalPlacement;
    originY = side;
    overlayX = align as HorizontalPlacement;
    overlayY = options.disableOpposite ? side : OPPOSITE_PLACEMENT[side];
  } else {
    originY = overlayY = align as VerticalPlacement;
    originX = side as HorizontalPlacement;
    overlayX = options.disableOpposite ? side : OPPOSITE_PLACEMENT[side];
    overlayY = align as VerticalPlacement;
  }

  return {
    originX,
    originY,
    overlayX,
    overlayY,
  };
}

export function flipPlacement(placement: OverlayPlacement, axis: 'x' | 'y') {
  if (axis === 'x') {
    placement.overlayX = OPPOSITE_PLACEMENT[placement.overlayX];
    placement.originX = OPPOSITE_PLACEMENT[placement.originX];

    if (typeof placement.offsetX === 'number') {
      placement.offsetX = -placement.offsetX;
    }
  } else {
    placement.overlayY = OPPOSITE_PLACEMENT[placement.overlayY];
    placement.originY = OPPOSITE_PLACEMENT[placement.originY];

    if (typeof placement.offsetY === 'number') {
      placement.offsetY = -placement.offsetY;
    }
  }
}

export function isTemplateContent<TContentProps, TContext>(
  content: OverlayContent<TContentProps, TContext>,
): content is OverlayContentTemplate<TContext> {
  const templateContent = content as OverlayContentTemplate<TContext>;

  return Boolean(templateContent.templateRef && templateContent.viewContainerRef);
}

export function isComponentContent<TContentProps, TContext>(
  content: OverlayContent<TContentProps, TContext>,
): content is OverlayContentComponent<TContentProps> {
  const componentContent = content as OverlayContentComponent<TContentProps>;

  return Boolean(componentContent.component && componentContent.viewContainerRef);
}
