export function skipInitialCall<
  TFunc extends (...args: any[]) => any,
  TParams extends Parameters<TFunc>,
  TReturn extends ReturnType<TFunc>,
>(fn: TFunc, ignoreCallsCount = 1): (...args: TParams) => TReturn | undefined {
  let calledTimes = 0;

  return function (this: any, ...args: TParams): TReturn | undefined {
    if (calledTimes >= ignoreCallsCount) {
      return fn.apply(this, args);
    }

    calledTimes++;
  };
}
