import {Injectable} from '@angular/core';
import {action, makeObservable, observable} from 'mobx';

import {LcapPageContentResponse} from '@shared/modules/lcap-page/lcap-page.types';
import {LcapPageDataSources, LcapPageRelatedData, LcapPageRequestData} from '@shared/modules/lcap/lcap.types';
import {DialogInstance} from '@shared/modules/dialog/dialog.types';

import {AwsWafApiKey} from '../types/aws-waf-captcha.types';
import {CaptchaDialogComponent} from '../components/captcha-dialog/captcha-dialog.component';

import {HttpResource, Request} from './http-resource';
import {PortalApp} from './portal-apps.service';
import {RootDialogService} from './root-dialog.service';

export interface AppPublicPageResponse {
  app_slug: PortalApp['slug'];
  page: AppPublicPage;
  captcha_api_key?: AwsWafApiKey;
}

export interface AppPublicPage {
  id: string; // access token
  slug: string;
  schema: LcapPageDataSources;
  content: LcapPageContentResponse;
}

@Injectable({
  providedIn: 'root',
})
export class PublicAccessService {
  @observable appSlug: PortalApp['slug'] | null = null;
  @observable accessToken: AppPublicPage['id'] | null = null;
  @observable captchaApiKey: AwsWafApiKey | null = null;
  @observable enabled = false;

  @observable.ref private captchaDialog: DialogInstance | null = null;

  private resource = new HttpResource({
    url: '/portal/api/anonymous/pages/{{accessToken}}.json',
  });

  constructor(private rootDialog: RootDialogService) {
    makeObservable(this);
  }

  getPage(accessToken: AppPublicPage['id']): Request<AppPublicPageResponse> {
    return this.resource.get({accessToken});
  }

  submitPage(fields: LcapPageRequestData, relations: LcapPageRelatedData): Request<void> {
    return this.resource.post(
      {accessToken: this.accessToken},
      {
        business_object: {...fields, relations},
      },
    );
  }

  @action
  set({app_slug, page, captcha_api_key}: AppPublicPageResponse) {
    this.appSlug = app_slug;
    this.captchaApiKey = captcha_api_key ?? null;
    this.accessToken = page.id;
  }

  @action
  enable() {
    this.enabled = true;
  }

  @action
  disable() {
    this.enabled = false;
  }

  @action
  reset() {
    this.appSlug = null;
    this.captchaApiKey = null;
    this.accessToken = null;
    this.captchaDialog?.close();
  }

  @action
  showCaptchaDialog(): Promise<boolean> {
    this.captchaDialog = this.rootDialog.open(CaptchaDialogComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      contentInputs: {
        captchaApiKey: this.captchaApiKey!,
      },
    });

    return this.captchaDialog.result;
  }
}
