export enum KEY {
  ESC = 27,
  UP = 38,
  DOWN = 40,
  LEFT = 37,
  RIGHT = 39,
  ENTER = 13,
  TAB = 9,
  BACKSPACE = 8,
  SPACE = 32,
  PLUS = 187,
  COMMA = 188,
  MINUS = 189,
  LEFT_BRACKET = 219,
  ZERO = 48,
}
