export const HEX_COLOR_REGEXP = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

export function hexToRgba(hex: string, opacity: number): string {
  const [red, green, blue] = hex
    .replace(HEX_COLOR_REGEXP, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)!
    .map(x => parseInt(x, 16));

  return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
}

export function isHexColor(color: string): boolean {
  return /^#([0-9A-F]{3}){1,2}$/i.test(color);
}
