import {HtmlScrollableContainer} from './scrollable-container';

export function getClosestScrollableContainer(
  element: Element | null,
  currentlyScrollable = false,
): HtmlScrollableContainer | null {
  let elem: Element | null = element;

  while (elem) {
    let canBeScrollable: boolean;

    if (elem.tagName === 'BODY') {
      elem = document.documentElement;
    }

    const {overflowY, position} = window.getComputedStyle(elem);

    if (elem === document.documentElement) {
      canBeScrollable = true;
    } else {
      canBeScrollable = overflowY === 'scroll' || overflowY === 'auto';
    }

    if (canBeScrollable && (!currentlyScrollable || elem.scrollHeight > elem.clientHeight)) {
      break;
    }

    if (position === 'fixed' || position === 'sticky') {
      /*
       * We should not go outside of the fixed elements as scrolling their scrollable parent doesn't affect their
       * position
       */
      elem = null;
      break;
    }

    elem = elem.parentElement;
  }

  return elem ? new HtmlScrollableContainer(elem) : null;
}
