<div
  class="empty empty_{{ size }}"
  [class.empty_no-border]="!hasBorder"
  [class.empty_no-background]="!hasBackground"
  [class.empty_no-padding]="!hasPadding"
>
  <ng-container *ngIf="hasImage">
    <div
      *ngIf="image !== 'spinner'; else spinnerTmpl"
      class="empty__img empty__img_{{ placeholder ? 'placeholder' : image }}"
    ></div>
  </ng-container>
  <h2 *ngIf="header || placeholder" class="empty__title">
    <w-template-or-text *ngIf="header; else placeholderHeaderTmpl" [value]="header" />
  </h2>
  <div *ngIf="description || placeholder" class="empty__text">
    <w-template-or-text *ngIf="description; else placeholderDescriptionTmpl" [value]="description" />
  </div>

  <div *ngIf="actionsTemplate" class="empty__actions">
    <ng-container [ngTemplateOutlet]="actionsTemplate" />
  </div>
</div>

<ng-template #spinnerTmpl>
  <w-spinner class="empty__img" size="large" />
</ng-template>

<ng-template #placeholderHeaderTmpl>
  <div class="empty__placeholder empty__placeholder_header"></div>
</ng-template>

<ng-template #placeholderDescriptionTmpl>
  <div class="empty__placeholder empty__placeholder_description"></div>
</ng-template>
