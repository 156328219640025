import {memoize} from 'lodash';
import {DateTime} from 'luxon';

export const getLocaleDateFormat = memoize((locale: string = navigator.language) => {
  const sampleDate = DateTime.utc(3456, 2, 1).reconfigure({outputCalendar: 'iso8601', locale});

  return sampleDate
    .toLocaleString(DateTime.DATE_SHORT)
    .replace(/0?1/, match => 'd'.repeat(match.length))
    .replace(/0?2/, match => 'M'.repeat(match.length))
    .replace('3456', 'yyyy');
});
