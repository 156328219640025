import {Rect} from '../types';

export class DynamicRect {
  top: number;
  left: number;
  private _width: number;
  private _height: number;

  constructor(rect: Rect) {
    this.top = rect.top;
    this.left = rect.left;
    this.width = rect.width;
    this.height = rect.height;
  }

  get right(): number {
    return this.left + this._width;
  }

  set right(value: number) {
    this.left = value - this._width;
  }

  get bottom(): number {
    return this.top + this._height;
  }

  set bottom(value: number) {
    this.top = value - this._height;
  }

  get width(): number {
    return this._width;
  }

  set width(value: number) {
    this._width = Math.max(0, value);
  }

  get height(): number {
    return this._height;
  }

  set height(value: number) {
    this._height = Math.max(0, value);
  }
}
