export function generateLooseTimeFormats(originalFormat: string): string[] {
  const formats: string[] = [
    originalFormat,
    originalFormat.replace(/(h){1,2}/i, (match, char) => (match.length === 1 ? char.repeat(2) : char)),
  ];

  if (originalFormat.includes('a')) {
    for (const format of [...formats]) {
      formats.push(format.replace(/\s?a/i, match => (match.length === 1 ? ' a' : 'a')));
    }
  }

  return formats;
}
