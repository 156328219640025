export class Timer {
  private start: number;
  private timerId: number;
  private remaining: number;

  private _isRunning: boolean;
  private _isStopped = false;

  constructor(
    private callback: () => void,
    delay: number,
  ) {
    this.remaining = delay;

    this.resume();
  }

  get isRunning(): boolean {
    return this._isRunning;
  }

  get isStopped(): boolean {
    return this._isStopped;
  }

  public pause() {
    if (this.isStopped || !this.isRunning) {
      return;
    }

    window.clearTimeout(this.timerId);
    this._isRunning = false;
    this.remaining -= Date.now() - this.start;

    if (this.remaining < 0) {
      this.remaining = 0;
    }
  }

  public resume() {
    if (this.isStopped || this.isRunning) {
      return;
    }

    this._isRunning = true;
    this.start = Date.now();
    this.timerId = window.setTimeout(() => {
      this.stop();
      this.callback();
    }, this.remaining);
  }

  public stop() {
    if (!this.isRunning) {
      return;
    }

    this._isStopped = true;
    this._isRunning = false;
    window.clearTimeout(this.timerId);
  }
}
