export const SCREEN_SIZES = {
  phone: 480,
  phablet: 600,
  tablet: 768,
  compact: 960,
  desktopSmall: 1120,
  desktopHD: 1280,
  desktopFullHD: 1920,
  desktopMax: Infinity,
} as const;

export type ScreenSizeName = keyof typeof SCREEN_SIZES;

export const SCREEN_SIZE_NAMES = Object.keys(SCREEN_SIZES) as ScreenSizeName[];
