import {ApplicationRef, ChangeDetectorRef, Directive, OnDestroy, OnInit} from '@angular/core';
import {IReactionDisposer, autorun, runInAction} from 'mobx';

const processedAppRefs = new Set<ApplicationRef>();

/**
 * This host directive is an analog to `*mobxAutorun` directive from `mobx-angular` package.
 * When added to `hostDirectives` of component - it'll automatically rerender it (via `detectChanges` call),
 * when any mobx `observable` or `computed` (that is used inside it) is changed.
 */
@Directive({
  selector: '[wReactive]',
  standalone: true,
})
export class ReactiveDirective implements OnInit, OnDestroy {
  protected dispose: IReactionDisposer | null = null;

  constructor(
    protected appRef: ApplicationRef,
    protected cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    // Optimizes update of observables (and related reactions), which are also marked as angular component inputs
    if (!processedAppRefs.has(this.appRef)) {
      processedAppRefs.add(this.appRef);

      const originalTick = this.appRef.tick;

      this.appRef.tick = function (...args: Parameters<ApplicationRef['tick']>) {
        return runInAction(() => originalTick.apply(this, args));
      };
    }

    this.dispose = autorun(() => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.dispose?.();
  }
}
