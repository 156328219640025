import {Injectable, Optional} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AbstractUserSessionService} from '../services/abstract-user-session.service';
import {AbstractAuthService} from '../services/abstract-auth.service';

const UNAUTHORIZED_HTTP_CODE = 401;

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private authUser: AbstractAuthService,
    @Optional() private userSession: AbstractUserSessionService | null,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (
          this.authUser.authenticated &&
          !this.userSession?.hasExpiredSessionPopup &&
          err instanceof HttpErrorResponse &&
          err.status === UNAUTHORIZED_HTTP_CODE
        ) {
          this.authUser.returnUrl = location.href;
          location.replace(this.authUser.signInUrl);

          return EMPTY;
        }

        return throwError(err);
      }),
    );
  }
}
