import {forEach, has, pull} from 'lodash';
import {Injectable} from '@angular/core';

import {KEY} from '../constants/key-codes';

import {Log} from './log';

interface EventHandlers {
  [eventType: string]: EventHandler;
}

interface EventHandler {
  hostListener: EventListener;
  listeners: CaptureEventListener[];
}

/**
 * If listener returns `true` then:
 *  - All other listeners for this event type will not be called
 *  - `event.stopImmediatePropagation()` will be called
 *  - `event.preventDefault` will be called
 */
export type CaptureEventListener = (event: Event) => boolean | void;

export interface CaptureEventListeners {
  [eventType: string]: CaptureEventListener;
}

const windowEvents = new Set(['resize']);

@Injectable({
  providedIn: 'root',
})
export class GlobalCaptureEventListeners {
  private handlers: EventHandlers = {};
  private modifiedListeners = new Map<CaptureEventListener, CaptureEventListener>();

  constructor(private log: Log) {}

  add(events: CaptureEventListeners): void;
  add(eventType: string, listener: CaptureEventListener): void;

  add(eventType: CaptureEventListeners | string, listener?: CaptureEventListener) {
    if (typeof eventType === 'string') {
      const [name, ...modifiers] = eventType.split('.');

      if (modifiers.length) {
        listener = this.createModifiedListener(listener!, modifiers);
      }

      if (!this.handlers[name]) {
        this.handlers[name] = {
          hostListener: this.createHostListener(name),
          listeners: [],
        };
      }

      const handler = this.handlers[name];

      if (!handler.listeners.length) {
        this.getEventHost(name).addEventListener(name, handler.hostListener, true);
      }

      handler.listeners.unshift(listener!);
    } else {
      forEach(eventType, (handler, type) => this.add(type, handler));
    }
  }

  remove(events: CaptureEventListeners): void;
  remove(eventType: string, listener: CaptureEventListener): void;

  remove(eventType: CaptureEventListeners | string, listener?: CaptureEventListener) {
    if (typeof eventType === 'string') {
      const [name] = eventType.split('.', 1);

      const handler = this.handlers[name];

      if (!handler) {
        return;
      }

      if (this.modifiedListeners.has(listener!)) {
        pull(handler.listeners, this.modifiedListeners.get(listener!));
        this.modifiedListeners.delete(listener!);
      } else {
        pull(handler.listeners, listener);
      }

      if (!handler.listeners.length) {
        this.getEventHost(name).removeEventListener(eventType, handler.hostListener, true);
      }
    } else {
      forEach(eventType, (handler, type) => this.remove(type, handler));
    }
  }

  toggle(events: CaptureEventListeners, flag: boolean): void;
  toggle(eventType: string, listener: CaptureEventListener, flag: boolean): void;

  toggle(eventType: string | CaptureEventListeners, listener?: CaptureEventListener | boolean, flag?: boolean) {
    if (typeof eventType === 'string') {
      if (flag) {
        this.add(eventType, listener as CaptureEventListener);
      } else {
        this.remove(eventType, listener as CaptureEventListener);
      }
    } else {
      forEach(eventType, (handler, type) => this.toggle(type, handler, flag!));
    }
  }

  private createHostListener(eventType: string): EventListener {
    return (event: Event) => {
      for (const listener of [...this.handlers[eventType].listeners]) {
        if (listener.call(this, event) === true) {
          event.stopImmediatePropagation();
          event.preventDefault();

          return;
        }
      }
    };
  }

  private createModifiedListener(listener: CaptureEventListener, modifiers: string[]): CaptureEventListener {
    const originalListener = listener;

    for (const modifier of modifiers) {
      if (has(KEY, modifier.toUpperCase())) {
        listener = keyModifier(listener, modifier.toUpperCase());
        continue;
      }

      this.log.warn(`Unknown modifier "${modifier}" for global event`);
    }

    if (listener !== originalListener) {
      this.modifiedListeners.set(originalListener, listener);
    }

    return listener;
  }

  private getEventHost(eventType: string): Window | Document {
    return windowEvents.has(eventType) ? window : document;
  }
}

function keyModifier(listener: CaptureEventListener, key: string): CaptureEventListener {
  return (event: KeyboardEvent) => {
    if (event.keyCode === KEY[key]) {
      return listener(event);
    }
  };
}
